var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "head-right" },
    [
      _c(
        "el-input",
        {
          ref: "searchInput",
          staticClass: "input-with-select",
          attrs: { placeholder: "请输入", clearable: "" },
          on: {
            focus: function ($event) {
              _vm.showHistory = true
            },
            input: _vm.searchWord,
          },
          nativeOn: {
            mouseleave: function ($event) {
              return _vm.inputBlur.apply(null, arguments)
            },
          },
          model: {
            value: _vm.fileName,
            callback: function ($$v) {
              _vm.fileName = $$v
            },
            expression: "fileName",
          },
        },
        [
          _c(
            "template",
            { slot: "prepend" },
            [
              _c("avue-input-tree", {
                attrs: {
                  props: _vm.inputTreeProps,
                  "default-expand-all": "",
                  placeholder: "请选择",
                  dic: _vm.dic,
                },
                on: { change: _vm.chooseKnowledge },
                model: {
                  value: _vm.knowledgeValue,
                  callback: function ($$v) {
                    _vm.knowledgeValue = $$v
                  },
                  expression: "knowledgeValue",
                },
              }),
            ],
            1
          ),
          _c("el-button", {
            staticStyle: { color: "#FFFFFF" },
            style: { backgroundColor: _vm.themeColor },
            attrs: { slot: "append", icon: "el-icon-search" },
            on: { click: _vm.searchGrid },
            slot: "append",
          }),
        ],
        2
      ),
      _vm.fileName && _vm.showSearchList && _vm.searchList.length > 0
        ? _c(
            "div",
            {
              staticClass: "history-dialog",
              on: {
                mouseleave: function ($event) {
                  _vm.showSearchList = false
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: { "max-height": "500px", "overflow-y": "auto" },
                },
                _vm._l(_vm.searchList, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "history-content",
                      on: {
                        click: function ($event) {
                          return _vm.clickSearch(item.title)
                        },
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(item.title))])]
                  )
                }),
                0
              ),
            ]
          )
        : _vm._e(),
      !_vm.fileName && _vm.showHistory && _vm.browseList.length > 0
        ? _c(
            "div",
            {
              staticClass: "history-dialog",
              on: {
                mouseleave: function ($event) {
                  _vm.showHistory = false
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: { "max-height": "500px", "overflow-y": "auto" },
                },
                _vm._l(_vm.browseList, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "history-content",
                      on: {
                        click: function ($event) {
                          return _vm.clickSearch(item.fileName)
                        },
                        mousemove: function ($event) {
                          return _vm.showClear(item)
                        },
                        mouseleave: function ($event) {
                          return _vm.hideClear(item)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { style: { color: item.show ? _vm.themeColor : "" } },
                        [_vm._v(_vm._s(item.fileName))]
                      ),
                      item.show
                        ? _c(
                            "span",
                            {
                              style: { color: _vm.themeColor },
                              attrs: { underline: false },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.removeHistory(item.browseId, "one")
                                },
                              },
                            },
                            [_vm._v("清除")]
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "btns" }, [
                _c(
                  "span",
                  {
                    style: { color: _vm.themeColor },
                    attrs: { underline: false },
                    on: {
                      click: function ($event) {
                        return _vm.removeHistory("", "all")
                      },
                    },
                  },
                  [_vm._v("清除历史")]
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }