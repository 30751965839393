<template>
    <div class="head-right">
        <el-input class="input-with-select" ref="searchInput" placeholder="请输入" clearable v-model="fileName"
            @focus="showHistory = true" @input="searchWord" @mouseleave.native="inputBlur">
            <template slot="prepend">
                <avue-input-tree :props="inputTreeProps" default-expand-all v-model="knowledgeValue" placeholder="请选择"
                    :dic="dic" @change="chooseKnowledge" ></avue-input-tree>
            </template>
            <el-button slot="append" icon="el-icon-search" @click="searchGrid" :style="{backgroundColor:themeColor}" style="color: #FFFFFF"></el-button>
        </el-input>
        <div class="history-dialog" v-if="fileName && showSearchList && searchList.length > 0"
            @mouseleave="showSearchList = false">
            <div style="max-height: 500px; overflow-y: auto">
                <div class="history-content" v-for="item in searchList" :key="item.id" @click="clickSearch(item.title)">
                    <span>{{ item.title }}</span>
                </div>
            </div>
        </div>
        <div class="history-dialog" v-if="!fileName && showHistory && browseList.length > 0"
            @mouseleave="showHistory = false">
            <div style="max-height: 500px; overflow-y: auto">
                <div class="history-content" v-for="item in browseList" :key="item.id"
                    @click="clickSearch(item.fileName)" @mousemove="showClear(item)" @mouseleave="hideClear(item)">
                    <span :style="{ color: item.show ? themeColor : '' }">{{ item.fileName }}</span>
                    <span :style="{ color: themeColor }" :underline="false"
                        @click.stop="removeHistory(item.browseId, 'one')" v-if="item.show">清除</span>
                </div>
            </div>
            <div class="btns">
                <span :style="{ color: themeColor }" :underline="false" @click="removeHistory('', 'all')">清除历史</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { listBrowse, removeBrowse, search, getKnowledgeClassifyTree } from "@/api/knowledgeClassifcation/knowledge";
export default {
    data() {
        return {
            fileName: "",
            browseListAll: [],
            showHistory: false,
            browseList: [], //浏览历史
            browsePage: {
                currentPage: 1,
                total: 0,
            },
            searchList: [],
            showSearchList: false,
            knowledgeValue: 'all',
            inputTreeProps: {
                label: "classifyName",
                value: "id",
            },
            dic: [],
        };
    },

    components: {},

    computed: {
        ...mapGetters(["themeColor"]),
        ids() {
            let ids = [];
            this.browseListAll.forEach((ele) => {
                ids.push(ele.browseId);
            });
            return ids.join(",");
        },
    },

    mounted() {
        this.initTree();
        this.onLoadBrowse();
    },

    methods: {
        initTree() {
            getKnowledgeClassifyTree()
                .then((result) => {
                    this.dic = result.data.data;
                    this.dic.unshift({id:'all',classifyName:'全部'})
                })
        },
        chooseKnowledge(e) {
            if (e) {
                if(e == 'all') {
                    this.$emit('searchGridAll',this.fileName)
                } else {
                    this.$emit('searchClass', e)
                }

            }
        },
        inputBlur() {
            this.$refs.searchInput.blur();
        },
        searchWord() {
            this.showSearchList = true;
            search(this.fileName).then((res) => {
                if (res.status == 200) {
                    this.searchList = res.data;
                }
            });
        },
        searchGrid() {
            this.$emit('searchGrid', this.fileName)
        },
        clickSearch(fileName) {
            this.fileName = fileName;
            this.$emit('searchGrid', this.fileName)
        },
        showClear(item) {
            item.show = true;
        },
        hideClear(item) {
            item.show = false;
        },
        removeHistory(id, type) {
            let ids = id;
            if (type == "all") {
                ids = this.ids;
            }
            removeBrowse(ids).then((res) => {
                if (res.data.code == 200) {
                    this.$message({
                        type: "success",
                        message: "操作成功",
                    });
                    this.showHistory = false;
                    this.browsePage.currentPage = 0;
                    this.onLoadBrowse();
                }
            });
        },
        onLoadBrowse() {
            listBrowse(this.browsePage.currentPage, 9999).then((res) => {
                const data = res.data.data;
                this.browseListAll = data.records;
                data.records = this.deWeightFour(data.records);
                this.browseList = data.records.slice(0, 10);
                this.browseList.forEach((item) => {
                    this.$set(item, "show", false);
                });
                this.browsePage.total = data.total;
            });
        },
        deWeightFour(arr) {
            let obj = {};
            arr = arr.reduce(function (a, b) {
                obj[b.fileName] ? "" : (obj[b.fileName] = true && a.push(b));
                return a;
            }, []);
            return arr;
        },
    },
}
</script>

<style scoped lang='scss'>
.head-right {
    position: absolute;
    left: 30%;
    top: 6px;

    .el-input {
        width: 300px;
    }

    .el-radio-group {
        padding-left: 10px;
    }

    .history-dialog {
        width: 350px;
        position: absolute;
        top: 32px;
        padding: 12px;
        background: #fff;
        z-index: 999;
        border-left: 1px solid #DCDFE6;
        border-right: 1px solid #DCDFE6;
        border-bottom: 1px solid #DCDFE6;

        .history-content {
            line-height: 30px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 12px;

            &:hover {
                background: #F5F7FA;
            }

            span {
                font-size: 14px;
                color: #606266;
                padding-left: 12px;
            }
        }

        .btns {
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 14px;
        }
    }
}

::v-deep .el-input-group__prepend .el-input {
    width: 100px !important;
}

::v-deep .el-input-group__prepend .el-input .el-input__inner {
    width: 100px !important;
}

::v-deep .input-with-select .el-input__inner {
    width: 240px;
    margin: 0;
}
</style>
